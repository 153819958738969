import { ButtonBlock, Button } from '@politechdev/blocks-design-system'
import { useTranslation } from 'react-i18next'

const GalleryButtonBlock = ({ currentPacket, scanNumber }) => {
  const { t } = useTranslation()

  const getHasPledgeCard = packet =>
    packet.forms.some(form => form.pledge_card_url)
  const getPacketUsesOCR = packet => !!packet.ocr

  const usePledgeCardGallery =
    getHasPledgeCard(currentPacket) && !getPacketUsesOCR(currentPacket)

  const linkId = usePledgeCardGallery
    ? currentPacket.id
    : currentPacket.shift_id
  const gallery = usePledgeCardGallery
    ? '/quality_control/pledge_gallery'
    : '/quality_control/gallery'
  const pledgeLink = `${gallery}/${linkId}?type=Form&firstScanId=${scanNumber}`
  const scanLink = `${gallery}/${linkId}?scans[]=${scanNumber}`

  return (
    <ButtonBlock>
      <Button
        flat
        primary
        iconChildren="picture_as_pdf"
        iconBefore={false}
        onClick={() =>
          window.open(usePledgeCardGallery ? pledgeLink : scanLink, '_blank')
        }
        disabled={!scanNumber}
      >
        {t('View Scan')}
      </Button>
      <Button
        flat
        primary
        iconChildren="library_add"
        iconBefore={false}
        onClick={() => window.open(`${gallery}/${linkId}`, '_blank')}
      >
        {t(`${usePledgeCardGallery ? 'Pledge Card' : 'Packet'} Gallery`)}
      </Button>
    </ButtonBlock>
  )
}

export default GalleryButtonBlock
