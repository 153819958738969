import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { defaultTo, get, isNumber } from 'lodash'
import { View, ViewContainer, CardError, CustomFields } from 'components'
import {
  Grid,
  Checkbox,
  TextField,
  NumberField,
  Section,
  FieldBlock,
  ButtonBlock,
  Button,
  PageHeader,
} from '@politechdev/blocks-design-system'
import { useRoutePathParams, useRequest, useReactRouter } from 'hooks'
import { useForm } from 'contexts'
import { logErrorResponse } from 'utils/logging'
import { setPacketInfo } from 'requests/qualityControl'
import { fetchShift, postDigitalBatch } from 'requests/shifts'
import ShiftPdfViewer from './ShiftPdfViewer/ShiftPdfViewer'
import {
  DataEntryUploadStorage as UploadStorage,
  DataEntryFormStorage as FormStorage,
  COVER_SHEET_INDEX,
} from './storageUtils'
import { SHIFT_TYPE } from './constants'
import { getBatchForAutoSubmission } from './ShiftDataEntry/utils'

const ShiftPacketInformation = () => {
  const { t } = useTranslation()
  const [{ id: shiftId, shiftType }] = useRoutePathParams()

  const { history } = useReactRouter()
  const { formData, setField, setFormData } = useForm()

  const localStoragePacketPageCount =
    UploadStorage.getCountRecordsInStorage(shiftId)

  const [emptyBatch, setEmptyBatch] = useState(false)

  const [prePacketUrl, setPrePacketUrl] = useState()

  const [hasDataEntryConfig, setHasDataEntryConfig] = useState(false)

  useEffect(() => {
    setEmptyBatch(
      shiftType === SHIFT_TYPE.REGISTRATION && localStoragePacketPageCount < 2
    )
  }, [shiftType, localStoragePacketPageCount])

  useEffect(() => {
    if (shiftType === SHIFT_TYPE.REGISTRATION) {
      setPrePacketUrl(
        UploadStorage.getRecordFromStorage(shiftId, COVER_SHEET_INDEX).url
      )
    }
  }, [shiftType])

  const {
    makeRequest: requestShift,
    isLoading: shiftLoading,
    hasErrors: shiftError,
    isRequestComplete: shiftFetched,
    response: shiftResponse,
  } = useRequest(fetchShift, {
    onSuccess: ({
      shift: {
        turf,
        soft_count_cards_total_collected,
        soft_count_cards_complete_collected,
        soft_count_cards_incomplete_collected,
        soft_count_cards_with_phone_collected,
        soft_count_pre_registration_cards_collected,
        notes,
      },
    }) => {
      setHasDataEntryConfig(
        !!turf.voter_registration_config.data_entry_sections?.length
      )

      const customFieldConfig = turf.options.custom_field_configs?.find(
        c => c.table === 'shifts'
      )

      const customFieldsDefaults = customFieldConfig
        ? customFieldConfig.fields.reduce((defaults, field) => {
            if (field.type === 'integer') {
              defaults[field.name] = 0
            }

            return defaults
          }, {})
        : {}

      setFormData(formData => ({
        ...formData,
        soft_count_cards_total_collected,
        soft_count_cards_complete_collected,
        soft_count_cards_incomplete_collected,
        soft_count_cards_with_phone_collected,
        soft_count_pre_registration_cards_collected,
        notes,
        custom_field_data: customFieldsDefaults,
      }))
    },
  })

  const currentShift = defaultTo(shiftResponse?.shift, {})

  const {
    makeRequest: formRequest,
    isLoading: submitLoading,
    hasErrors: submitError,
  } = useRequest(setPacketInfo, {
    onSuccess: () => history.push(`/collect/${shiftType}/shifts`),
    onError: (error, args) =>
      logErrorResponse(error, args, 'Packet information error'),
  })

  const handleSuccesfulBatchUpload = async () => {
    await FormStorage.removeFromStorage(shiftId)
    await UploadStorage.removeFromStorage(shiftId)
    history.push(`/collect/voter_registration/shifts`)
  }

  const submitDigitalBatchRequest = useRequest(postDigitalBatch, {
    onSuccess: handleSuccesfulBatchUpload,
  })

  const autoSubmitBatch = () => {
    const batch = getBatchForAutoSubmission(shiftId, formData)
    submitDigitalBatchRequest.makeRequest(shiftId, batch)
  }

  const submitForm = e => {
    e.preventDefault()
    if (shiftType === SHIFT_TYPE.REGISTRATION) {
      if (hasDataEntryConfig) {
        FormStorage.addPacketInfoToStorage(shiftId, formData)
        history.push(
          `/collect/voter_registration/shifts/${shiftId}/data_entry/1`
        )
      } else {
        autoSubmitBatch()
      }
    } else {
      formRequest(shiftId, formData)
    }
  }

  useEffect(() => {
    requestShift(shiftId, {
      fields: [
        'id',
        'soft_count_cards_total_collected',
        'soft_count_cards_complete_collected',
        'soft_count_cards_incomplete_collected',
        'soft_count_cards_with_phone_collected',
        'soft_count_pre_registration_cards_collected',
        'notes',
        'custom_field_data',
        { forms: ['file_url', 'pledge_card_url'] },
        {
          packet: ['file_locator', { cover_sheet: ['file_url'] }],
        },
        { turf: ['voter_registration_config', 'options'] },
      ],
    })
  }, [shiftId])

  const shiftLoaded =
    shiftFetched &&
    !shiftError &&
    (currentShift.id === +shiftId || currentShift.slug === shiftId)

  const customFieldsConfig =
    currentShift.turf?.options?.custom_field_configs?.find(
      c => c.table === 'shifts'
    )

  const setCustomField = (field, val) =>
    setField(
      { ...formData.custom_field_data, [field]: val },
      'custom_field_data'
    )

  const areCountsConflicting =
    isNumber(formData.soft_count_cards_total_collected) &&
    isNumber(formData.soft_count_cards_complete_collected) &&
    isNumber(formData.soft_count_cards_incomplete_collected) &&
    formData.soft_count_cards_total_collected !==
      formData.soft_count_cards_complete_collected +
        formData.soft_count_cards_incomplete_collected

  const arePhoneFormsMoreThanTotalForms =
    isNumber(formData.soft_count_cards_total_collected) &&
    isNumber(formData.soft_count_cards_with_phone_collected) &&
    formData.soft_count_cards_with_phone_collected >
      formData.soft_count_cards_total_collected

  const isStaticFormValid =
    isNumber(formData.soft_count_cards_with_phone_collected) &&
    isNumber(formData.soft_count_pre_registration_cards_collected) &&
    !areCountsConflicting &&
    !arePhoneFormsMoreThanTotalForms

  const isCustomFormValid =
    !customFieldsConfig ||
    !(customFieldsConfig?.fields || []).some(field => {
      const value = get(formData.custom_field_data, field.name)
      const isInvalid = !value && value !== 0
      return field.required && isInvalid
    })

  const isFormValid = isStaticFormValid && isCustomFormValid

  const packet = shiftResponse?.shift?.packet
  const forms = shiftResponse?.shift?.forms || []

  return (
    <View>
      <PageHeader title={t('Packet information')} />
      <ViewContainer
        loading={
          shiftLoading || submitLoading || submitDigitalBatchRequest.isLoading
        }
      >
        <CardError
          hide={!shiftError}
          message={t("We're unable to retrieve this shift")}
        />
        <CardError
          hide={!submitError}
          message={t("We're unable to save packet information")}
        />
        <CardError
          hide={!submitDigitalBatchRequest.hasErrors}
          message={t("We're unable to upload this packet")}
        />
        {shiftLoaded && (
          <Grid>
            <Section>
              <form onSubmit={submitForm}>
                <FieldBlock>
                  <Checkbox
                    id="empty_batch_toggle"
                    label={t('Canvasser collected at least 1 form')}
                    checked={!emptyBatch}
                    onChange={() => setEmptyBatch(!emptyBatch)}
                  />
                </FieldBlock>
                <FieldBlock>
                  <NumberField
                    id="cards_collected"
                    disabled={emptyBatch}
                    required
                    min={0}
                    label={t('Registration forms collected')}
                    value={formData.soft_count_cards_total_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_total_collected')
                    }
                    error={areCountsConflicting}
                    errorMessage={t(
                      'Does not equal the complete and incomplete registration form collected fields'
                    )}
                  />
                  <NumberField
                    id="cards_complete"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms complete')}
                    min={0}
                    value={formData.soft_count_cards_complete_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_complete_collected')
                    }
                  />
                  <NumberField
                    id="cards_incomplete"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms incomplete')}
                    min={0}
                    value={formData.soft_count_cards_incomplete_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_incomplete_collected')
                    }
                  />
                  <NumberField
                    id="cards_with_phone"
                    disabled={emptyBatch}
                    required
                    label={t('Registration forms with phone')}
                    min={0}
                    error={arePhoneFormsMoreThanTotalForms}
                    errorMessage={t(
                      'Cannot have more registration forms with phone than total collected registration forms'
                    )}
                    value={formData.soft_count_cards_with_phone_collected}
                    onChange={val =>
                      setField(+val, 'soft_count_cards_with_phone_collected')
                    }
                  />
                  <NumberField
                    id="pre_reg_cards"
                    disabled={emptyBatch}
                    required
                    label={t('Pre-reg forms')}
                    min={0}
                    value={formData.soft_count_pre_registration_cards_collected}
                    onChange={val =>
                      setField(
                        +val,
                        'soft_count_pre_registration_cards_collected'
                      )
                    }
                  />
                </FieldBlock>
                {!!customFieldsConfig && (
                  <FieldBlock>
                    <CustomFields
                      fields={customFieldsConfig.fields}
                      currentData={formData.custom_field_data || {}}
                      onChange={setCustomField}
                      disabled={emptyBatch}
                    />
                  </FieldBlock>
                )}
                <FieldBlock variant="large">
                  <TextField
                    id="notes"
                    disabled={emptyBatch}
                    label={t('Notes')}
                    value={formData.notes || ''}
                    onChange={val => setField(val, 'notes')}
                    type="text"
                  />
                </FieldBlock>
                <ButtonBlock justify="left">
                  <Button.Accent
                    disabled={
                      submitLoading ||
                      submitDigitalBatchRequest.isLoading ||
                      (!isFormValid && !emptyBatch)
                    }
                    type="submit"
                  >
                    {t('Save')}
                  </Button.Accent>
                </ButtonBlock>
              </form>
            </Section>
            <Section>
              {prePacketUrl && !packet && <ShiftPdfViewer url={prePacketUrl} />}
              {packet && (
                <ShiftPdfViewer.Composite
                  forms={forms}
                  coverSheet={packet.cover_sheet}
                  name={packet.file_locator?.metadata?.filename}
                  removedAt={packet.file_locator?.metadata?.redacted_at}
                />
              )}
            </Section>
          </Grid>
        )}
      </ViewContainer>
    </View>
  )
}

export default ShiftPacketInformation
