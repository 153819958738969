import { get, capitalize, set, omit, cloneDeep, isEqual } from 'lodash'
import humanizeString from 'humanize-string'
import {
  COVER_SHEET_INDEX,
  DataEntryFormStorage,
  DataEntryUploadStorage,
} from 'shifts/storageUtils'
import { formatDateStringAsISOString } from 'utils/formatting'
import { convertToRedactedField, fieldConfigs } from './fieldsConfig'
import {
  ADDRESS_GROUP_DEFAULT,
  CHECKBOX_DEFAULT,
  CHECKBOX_GROUP_DEFAULT,
  FIELD_TYPES,
  GROUP,
  NAME_GROUP_DEFAULT,
  RADIO_DEFAULT,
  RECORD_TYPES,
  REQUIRE_ERROR,
  REQUIRE_WARN,
  VDR_GROUP_DEFAULT,
  VDR_ISSUES,
  rADDRESS_SUFFIX,
  rADDRESS_SUFFIX_WITHOUT_DANGLE,
} from './constants'

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

export const getOptionArrayOfLength = length =>
  Array.from({ length }, (_, i) => ({
    value: i + 1,
    label: i + 1,
  }))

const convertStringToOption = o => ({
  value: o,
  label: humanizeString(o),
})

const setGroupDefaults = (defaults, field) => {
  const { defaultValue, attributeKeyTuples } = field
  const defaultsMap = Object.fromEntries(defaultValue)
  attributeKeyTuples.forEach(([attribute, groupKey]) => {
    const defaultValue =
      defaultsMap[groupKey] !== undefined ? defaultsMap[groupKey] : null
    set(defaults, attribute, defaultValue)
  })
}

const setFieldDefault = (defaults, field) => {
  const defaultValue =
    field.defaultValue !== undefined ? field.defaultValue : null
  set(defaults, field.attribute, defaultValue)
}

export const getDefaultFields = formConfig => {
  const defaultValues = {}
  formConfig.forEach(section => {
    section.fields.forEach(field => {
      const isGroupDefaults =
        Array.isArray(field.attributeKeyTuples) &&
        Array.isArray(field.defaultValue)
      if (isGroupDefaults) {
        setGroupDefaults(defaultValues, field)
      } else {
        setFieldDefault(defaultValues, field)
      }
    })
  })

  return defaultValues
}

const labelize = str => capitalize(str.replace(/_/g, ' '))

export const checkDoesVDRNeedAttention = (formConfig, formData, shift) => {
  const registrationConfig = formConfig.find(
    section => section.id === RECORD_TYPES.VOTER_REGISTRATION
  ) || { fields: [] }
  const hasVDRFields = registrationConfig.fields.find(
    field => field.component === FIELD_TYPES.VDR_BLOCK
  )

  if (!hasVDRFields) {
    return { hasIssues: false, issues: null }
  }

  if (!formData.hard_copy_collected) {
    return { hasIssues: false, issues: null }
  }

  if (!formData.county) {
    return { hasIssues: true, issues: VDR_ISSUES.COUNTY }
  }

  const { canvasser } = shift
  const isValidCountyForCanvasser =
    canvasser.vdrs.some(vdr => vdr.county === formData.county) ||
    formData.meta.ignoreVDR

  if (!isValidCountyForCanvasser) {
    return { hasIssues: true, issues: VDR_ISSUES.NO_MATCHING_VDR }
  }

  return { hasIssues: false, issues: null }
}

export const createAttributeMap = attributeKeyTuples =>
  attributeKeyTuples.reduce((fieldMap, [id, groupAttribute]) => {
    fieldMap[groupAttribute] = id
    return fieldMap
  }, {})

export const filenameCleanupRegex = /(_page_000)\w+.pdf/g

const getCoverSheetInfo = shiftId => {
  const coverSheetScan = DataEntryUploadStorage.getRecordFromStorage(
    shiftId,
    COVER_SHEET_INDEX
  )
  return coverSheetScan
}

const getPrefix = record => {
  if (record === RECORD_TYPES.PLEDGE_CARD) {
    return 'pledge_card'
  }
  if (record === RECORD_TYPES.PROOF_OF_RESIDENCE) {
    return RECORD_TYPES.PROOF_OF_RESIDENCE
  }

  return null
}

export const getHardCopyAttribute = section =>
  [getPrefix(section.id), 'hard_copy_collected'].filter(Boolean).join('.')

const withDocuments = ({ formData: { documents, ...form } }) => {
  if (documents) {
    Object.entries(documents).forEach(([id, doc]) => {
      switch (id) {
        case RECORD_TYPES.VOTER_REGISTRATION: {
          const { fileData } = doc
          form.file_locator = fileData
          break
        }
        case RECORD_TYPES.PLEDGE_CARD: {
          form.pledge_card_url = doc.fileUrl
          break
        }
        case RECORD_TYPES.PROOF_OF_RESIDENCE: {
          form.proof_of_residence_image_url = doc.fileUrl
        }
      }
    })
  }
  return { formData: { ...form, documents } }
}

const withRedactedFieldsArray =
  config =>
  ({ formData }) => {
    const potentiallyRedactedFields = config.reduce(
      (redacted, section) => [
        ...redacted,
        ...section.fields.filter(({ redacted }) => redacted),
      ],
      []
    )

    const { redacted_fields, missing_fields } =
      potentiallyRedactedFields.reduce(
        ({ redacted_fields, missing_fields }, fieldConfig) => {
          const hasValue = get(formData, fieldConfig.attribute)
          const attribute = fieldConfig.attribute.replace('has_', '')
          if (!hasValue) {
            return {
              missing_fields: { ...missing_fields, [attribute]: null },
              redacted_fields,
            }
          }
          return {
            redacted_fields: [...redacted_fields, attribute],
            missing_fields,
          }
        },
        { redacted_fields: [], missing_fields: {} }
      )

    return {
      formData: {
        ...formData,
        redacted_fields,
        ...missing_fields,
      },
    }
  }

const getAttributesFromFieldConfig = field => {
  if (field.attributeKeyTuples) {
    return field.attributeKeyTuples.map(([attribute]) => attribute)
  }
  if (field.redacted) {
    return [
      field.attribute,
      field.attribute.replace('has_', ''),
      'redacted_fields',
    ]
  }
  return field.attribute
}

const doesConfigSectionHaveEnterableFields = configSection =>
  !!configSection.fields.length

const getShouldSectionHaveData = (configSection, formData) => {
  const hasEnterableFields = doesConfigSectionHaveEnterableFields(configSection)
  const hasUpload = !!formData.documents?.[configSection.id]?.fileUrl
  const hasHardCopy = !!formData[getHardCopyAttribute(configSection)]

  if (configSection.id === RECORD_TYPES.VOTER_REGISTRATION) {
    return hasHardCopy
  }

  if (!hasEnterableFields) {
    return false
  }

  return hasUpload
}

export const withoutMissingSections =
  config =>
  ({ formData }) => {
    const fieldsToStrip = config.flatMap(configSection =>
      getShouldSectionHaveData(configSection, formData)
        ? []
        : configSection.fields
            .flatMap(getAttributesFromFieldConfig)
            .filter(
              attribute => attribute !== getHardCopyAttribute(configSection)
            )
    )
    const sanitizedFormData = omit(formData, fieldsToStrip)
    return { formData: sanitizedFormData }
  }

const stripTimeFromDateField = dateValue => dateValue.split('T')[0]

const convertListOptionsToValues = options =>
  options.map(option => option.value)

const formatOddFields =
  config =>
  ({ formData: originalFormData }) => {
    const formData = cloneDeep(originalFormData)
    config.forEach(section => {
      section.fields.forEach(field => {
        if (field.component === FIELD_TYPES.DATE) {
          const dateValue = get(formData, field.attribute)
          if (dateValue) {
            const formattedDate = stripTimeFromDateField(dateValue)
            set(formData, field.attribute, formattedDate)
          }
        }

        if (field.component === FIELD_TYPES.CHECKBOX_GROUP) {
          const selectedOptions = get(formData, field.attribute)
          if (selectedOptions?.length) {
            const formattedOptions = convertListOptionsToValues(selectedOptions)
            set(formData, field.attribute, formattedOptions)
          }
        }
      })
    })
    return {
      formData,
    }
  }

const asSubmittableData = ({
  formData: { meta, documents, ...submittableData },
}) => submittableData

export const formatFormForUpdate = (config, formData) =>
  [
    withRedactedFieldsArray(config),
    withoutMissingSections(config),
    formatOddFields(config),
    asSubmittableData,
  ].reduce((value, callback) => callback(value), { formData })

export const getFormattedPages = (config, pages) =>
  pages
    .map(withDocuments)
    .map(withRedactedFieldsArray(config))
    .map(withoutMissingSections(config))
    .map(formatOddFields(config))
    .map(asSubmittableData)

const getIsOCRNeeded = config =>
  !!config.find(
    section =>
      section.id === RECORD_TYPES.VOTER_REGISTRATION && section.hasDocument
  )

export const formatForSubmission = (config, shiftId, pages) => {
  const coversheet = getCoverSheetInfo(shiftId)
  return {
    cover_sheet: { file_locator: coversheet },
    ocr: getIsOCRNeeded(config),
    forms: getFormattedPages(config, pages.slice(1)),
    shift: pages[COVER_SHEET_INDEX].formData,
    filename: coversheet.metadata.filename.replace(
      filenameCleanupRegex,
      '.pdf'
    ),
  }
}

const formatAutoSubmitPages = uploads =>
  uploads.map(({ _url, ...fileData }) => ({
    file_locator: fileData,
  }))

export const getBatchFilename = filename =>
  filename.replace(filenameCleanupRegex, '.pdf')

export const getBatchForAutoSubmission = (shiftId, formData) => {
  const coversheet = getCoverSheetInfo(shiftId)
  const uploads = DataEntryUploadStorage.getFromStorage(shiftId)
  return {
    cover_sheet: { file_locator: coversheet },
    ocr: true,
    forms: formatAutoSubmitPages(uploads.data.slice(1)),
    shift: formData,
    filename: getBatchFilename(coversheet.metadata.filename),
  }
}

export const getSelectedScanIds = shiftId => {
  const storedRecord = DataEntryFormStorage.getFromStorage(shiftId, {
    data: [],
  })
  const forms = storedRecord.data
  const scanIds = new Set()
  Object.values(forms).forEach(({ formData }) => {
    if (formData?.documents) {
      Object.values(formData.documents).forEach(document => {
        scanIds.add(document.scanNumber)
      })
    }
  })
  return scanIds
}

export const getIsAllScansSelected = shiftId =>
  getSelectedScanIds(shiftId).size ===
  DataEntryUploadStorage.getCountRecordsInStorage(shiftId)

const generateSectionProperties = ({ record, fields }) => {
  const hasDocument = !!fields.find(f => f.column === 'file_locator')

  switch (record) {
    case RECORD_TYPES.VOTER_REGISTRATION: {
      return {
        id: record,
        label: 'Registration form',
        hasDocument,
        scanRequired: hasDocument,
      }
    }
    case RECORD_TYPES.PLEDGE_CARD: {
      return {
        id: record,
        label: 'Pledge card',
        hasDocument,
        scanRequired: true,
      }
    }
    case RECORD_TYPES.PROOF_OF_RESIDENCE: {
      return {
        id: record,
        label: 'Proof of residence',
        hasDocument,
        scanRequired: true,
      }
    }
  }
}

const getComponentDefaultValue = type => {
  switch (type) {
    case FIELD_TYPES.RADIO:
      return RADIO_DEFAULT
    case FIELD_TYPES.CHECKBOX:
      return CHECKBOX_DEFAULT
    case FIELD_TYPES.CHECKBOX_GROUP:
      return CHECKBOX_GROUP_DEFAULT
    case FIELD_TYPES.NAME_BLOCK:
      return NAME_GROUP_DEFAULT
    case FIELD_TYPES.ADDRESS_BLOCK:
      return ADDRESS_GROUP_DEFAULT
    case FIELD_TYPES.VDR_BLOCK:
      return VDR_GROUP_DEFAULT
    default:
      return undefined
  }
}

const getTextType = type => {
  if (type === 'email') {
    return 'email'
  }

  if (type === 'phone') {
    return 'tel'
  }
}

const getComponentForCustomField = (type, options) => {
  if (type === 'string') {
    if (options) {
      return FIELD_TYPES.RADIO
    }

    return FIELD_TYPES.TEXT
  }

  if (type === 'email') {
    return FIELD_TYPES.TEXT
  }

  if (type === 'phone') {
    return FIELD_TYPES.TEXT
  }

  if (type === 'boolean') {
    return FIELD_TYPES.CHECKBOX
  }

  if (type === 'list') {
    return FIELD_TYPES.CHECKBOX_GROUP
  }

  if (type === 'address') {
    return FIELD_TYPES.ADDRESS_BLOCK
  }

  if (type === 'country_state') {
    return FIELD_TYPES.COUNTRY_STATE_SELECT
  }

  if (type === 'vdr') {
    return FIELD_TYPES.VDR_BLOCK
  }

  if (type === 'date') {
    return FIELD_TYPES.DATE
  }
}

const buildFieldProps = (field, prefix) => {
  if (field.column && field.column !== 'file_locator') {
    const prefixAttr = prefix ? `${prefix}.${field.column}` : field.column
    const baseConfig = {
      ...fieldConfigs[field.column],
      attribute: prefixAttr,
      attributeKeyTuples: fieldConfigs[field.column].groupAttributeKey
        ? [[prefixAttr, fieldConfigs[field.column].groupAttributeKey]]
        : undefined,
      requirementBehavior: fieldConfigs[field.column].groupAttributeKey
        ? [[prefixAttr, field.require]]
        : field.require,
    }
    return field.redacted
      ? convertToRedactedField({ ...baseConfig, redacted: true })
      : baseConfig
  }

  const prefixAttr = prefix
    ? `${prefix}.${field.other_field_key}`
    : `extras.${field.other_field_key}`

  const configBase = { ...fieldConfigs[field.other_field_key] }

  const component =
    configBase.component ||
    getComponentForCustomField(field.other_field_type, field.options)

  if (field.other_field_type === 'vdr') {
    return {
      groupAttributeKey: field.other_field_key,
      component,
      attribute: field.other_field_key,
      group: GROUP.VDR_FIELDS,
      attributeKeyTuples: [[prefixAttr, field.other_field_key]],
      requirementBehavior: [[prefixAttr, field.require]],
      defaultValue: getComponentDefaultValue(component),
    }
  }

  if (field.other_field_type === 'address') {
    const addressPrefix = field.other_field_key.replace(rADDRESS_SUFFIX, '')
    const addressKey = `${addressPrefix}_address`
    return {
      ...configBase,
      groupAttributeKey: field.other_field_key,
      component,
      attribute: addressKey,
      label: labelize(addressKey),
      custom: true,
      group: configBase.group || addressKey,
      attributeKeyTuples: [
        [
          prefixAttr,
          field.other_field_key.replace(new RegExp(`${addressPrefix}_`), ''),
        ],
      ],
      requirementBehavior: [[prefixAttr, field.require]],
      defaultValue: getComponentDefaultValue(component),
    }
  }

  if (field.other_field_key) {
    return {
      ...configBase,
      component,
      attribute: prefixAttr,
      label: field.other_field_label || labelize(field.other_field_key),
      custom: true,
      options: field.options?.map(convertStringToOption),
      defaultValue: getComponentDefaultValue(component),
      type: configBase.type || getTextType(field.other_field_type),
      group: configBase.group || null,
      attributeKeyTuples: configBase.group
        ? [[prefixAttr, field.other_field_key]]
        : undefined,
      requirementBehavior: configBase.group
        ? [[prefixAttr, field.require]]
        : field.require,
    }
  }
}

export const generateFieldProperties = (fields, prefix) => {
  const fieldProps = fields
    .map(field => buildFieldProps(field, prefix))
    .filter(Boolean)

  return fieldProps.reduce((fields, field) => {
    const lastField = fields.at(-1)
    if (field.group && lastField?.group === field.group) {
      lastField.attributeKeyTuples = [
        ...lastField.attributeKeyTuples,
        ...field.attributeKeyTuples,
      ]
      lastField.requirementBehavior = [
        ...lastField.requirementBehavior,
        ...field.requirementBehavior,
      ].filter(([, behavior]) => behavior)
      return fields
    }
    return [...fields, field]
  }, [])
}

export const generateFormConfig = formConfig =>
  formConfig.map(section => {
    const { fields, record } = section
    return {
      ...generateSectionProperties(section),
      fields: generateFieldProperties(fields, getPrefix(record)),
    }
  })

const notNull = value => value !== null

const doesFieldHaveValue = (field, formData) => {
  switch (field.component) {
    case FIELD_TYPES.TEXT: {
      const { attribute } = field
      const value = get(formData, attribute)
      if (typeof value === 'string') {
        return value && value.trim()
      }
      if (typeof value === 'number') {
        return !Number.isNaN(value)
      }
      return false
    }
    case FIELD_TYPES.SELECT: {
      const { attribute } = field
      const value = get(formData, attribute)
      return !value
    }
    case FIELD_TYPES.CHECKBOX_GROUP: {
      const { attribute } = field
      const value = get(formData, attribute)
      return Array.isArray(value) && value.length
    }
    case FIELD_TYPES.DATE: {
      const { attribute } = field
      const value = get(formData, attribute)
      return notNull(value)
    }
    case FIELD_TYPES.CHECKBOX: {
      const { attribute } = field
      const value = get(formData, attribute)
      return value
    }
    case FIELD_TYPES.RADIO: {
      const { attribute } = field
      const value = get(formData, attribute)
      return notNull(value)
    }
    case FIELD_TYPES.ADDRESS_BLOCK: {
      const { attributeKeyTuples: attributes } = field
      return attributes.some(([attribute]) => {
        const value = get(formData, attribute)
        return value && value.trim()
      })
    }
    case FIELD_TYPES.NAME_BLOCK: {
      const { attributeKeyTuples: attributes } = field
      return attributes.some(([attribute]) => {
        const value = get(formData, attribute)
        return value && value.trim()
      })
    }
    case FIELD_TYPES.VDR_BLOCK: {
      const { attributeKeyTuples: attributes } = field
      return attributes.some(([attribute]) => {
        const value = get(formData, attribute)
        if (attribute === 'vdr_signature_date') {
          return notNull(value)
        }
        return value
      })
    }
    default: {
      return false
    }
  }
}

export const withCopiedFields = formData => {
  const clonedFormData = cloneDeep(formData)
  const copyData = get(clonedFormData, 'meta.copiedFields', {})
  Object.values(copyData).forEach(({ shouldCopy, copyPairs }) => {
    if (shouldCopy) {
      copyPairs.forEach(([attribute, copyTargetAttribute]) =>
        set(clonedFormData, attribute, get(clonedFormData, copyTargetAttribute))
      )
    }
  })
  return clonedFormData
}

export const checkSectionsNotEmpty = (config, formData) =>
  config.map(configSection => {
    const requiresFieldCheck = getShouldSectionHaveData(configSection, formData)
    return [
      configSection.id,
      !requiresFieldCheck ||
        configSection.fields.some(fieldConfig =>
          doesFieldHaveValue(fieldConfig, withCopiedFields(formData))
        ),
    ]
  })

const findFirstInstanceOfCopyableFieldGroup = (currentField, formConfig) => {
  let targetField = null
  let targetSection = null

  for (let i = 0; i < formConfig.length; i++) {
    const section = formConfig[i]
    const field = section.fields.find(
      field => field.component === currentField.component
    )

    if (field) {
      if (!isEqual(field, currentField)) {
        targetField = field
        targetSection = section
      }
      break
    }
  }

  return { targetField, targetSection }
}

export const getCopyFromData = (currentSectionId, currentField, formConfig) => {
  const currentSectionIndex = formConfig.findIndex(
    ({ id }) => id === currentSectionId
  )

  if (currentSectionIndex >= 0) {
    const { targetField, targetSection } =
      findFirstInstanceOfCopyableFieldGroup(
        currentField,
        formConfig.slice(0, currentSectionIndex + 1)
      )

    if (targetField && currentField) {
      const currentAttributes = currentField.attributeKeyTuples
      const copyFromAttributes = targetField.attributeKeyTuples

      const attributeMap = createAttributeMap(currentAttributes)
      const copyableAttributeMap = createAttributeMap(copyFromAttributes)

      return {
        copiedSectionLabel: targetSection.label,
        copiedFieldLabel: targetField.label,
        copyMap: Object.fromEntries(
          Object.keys(attributeMap)
            .map(key => [attributeMap[key], copyableAttributeMap[key]])
            .filter(
              ([attribute, copyFromAttribute]) =>
                !!attribute && !!copyFromAttribute
            )
        ),
      }
    }
  }
  return { copyMap: {} }
}

const getGroupSubfieldLabel = field => {
  const rootAttribute = field.attribute.split('.').pop()
  const isAddressAttribute = rADDRESS_SUFFIX.test(rootAttribute)

  let attribute = rootAttribute
  if (isAddressAttribute) {
    attribute = attribute.match(rADDRESS_SUFFIX_WITHOUT_DANGLE).shift()
  }

  switch (attribute) {
    case 'has_vdr_signature':
      return 'Has a canvasser (VDR) signature'
    case 'vdr_signature_date':
      return 'Signature date'
    case 'has_vdr_number':
      return 'Has VDR number'
    case 'street_address_one':
      return 'Address'
    case 'street_address_two':
      return 'Address line 2'
    case 'city':
      return 'City'
    case 'state':
      return 'State'
    case 'county':
      return 'County'
    case 'zipcode':
      return 'Zip code'
    case 'first_name':
      return 'First name'
    case 'middle_name':
      return 'Middle name'
    case 'last_name':
      return 'Last name'
    case 'name_suffix':
      return 'Suffix'
  }
}

const expandGroupFieldByBehavior = (behavior, groupField) =>
  groupField.requirementBehavior
    .filter(([, fieldBehavior]) => fieldBehavior === behavior)
    .map(([attribute]) => {
      const clonedField = cloneDeep(groupField)
      return {
        ...clonedField,
        attribute,
        attributeKeyTuples: clonedField.attributeKeyTuples.filter(
          ([attr]) => attr === attribute
        ),
        requirementBehavior: clonedField.requirementBehavior.filter(
          ([attr]) => attr === attribute
        ),
      }
    })

const getSectionFieldsWithBehavior = (behavior, section) => {
  const fieldsWithBehavior = section.fields.filter(({ requirementBehavior }) =>
    Array.isArray(requirementBehavior)
      ? requirementBehavior.some(
          ([, fieldBehavior]) => fieldBehavior === behavior
        )
      : requirementBehavior === behavior
  )

  return fieldsWithBehavior.flatMap(field =>
    Array.isArray(field.requirementBehavior)
      ? expandGroupFieldByBehavior(behavior, field)
      : cloneDeep(field)
  )
}

export const checkHasRequiredFields = (formConfig, formData) => {
  const requiredFields = formConfig
    .filter(section => getShouldSectionHaveData(section, formData))
    .flatMap(section => getSectionFieldsWithBehavior(REQUIRE_ERROR, section))

  const fieldErrors = requiredFields.reduce(
    (fieldErrors, field) =>
      doesFieldHaveValue(field, withCopiedFields(formData))
        ? fieldErrors
        : set(fieldErrors, field.attribute, 'required'),
    {}
  )

  return { hasRequiredFields: !Object.keys(fieldErrors).length, fieldErrors }
}

export const checkHasMissingFields = (formConfig, formData) => {
  const warningConfig = formConfig
    .filter(section => getShouldSectionHaveData(section, formData))
    .map(section => ({
      label: section.label,
      fields: getSectionFieldsWithBehavior(REQUIRE_WARN, section),
    }))

  const missingFieldsForDisplay = warningConfig
    .map(section => ({
      ...section,
      fields: section.fields
        .filter(field => !doesFieldHaveValue(field, withCopiedFields(formData)))
        .map(field => ({
          label: field.label,
          subfieldLabel: field.group ? getGroupSubfieldLabel(field) : undefined,
          custom: field.custom,
        })),
    }))
    .filter(section => !!section.fields.length)

  const hasMissingFields = !!missingFieldsForDisplay.flatMap(
    ({ fields }) => fields
  ).length

  return {
    hasMissingFields,
    missingFieldsForDisplay,
  }
}

const rPLEDGE_CARD_PREFIX = /^pledge_card\./
const PLEDGE_CARD_API_PREFIX = 'pledge_card_metadata.'

const withPrefixFromApi = attribute =>
  attribute.replace(rPLEDGE_CARD_PREFIX, PLEDGE_CARD_API_PREFIX)

const buildDocumentsFromResponse = (apiData, config) =>
  config.reduce((documents, configSection) => {
    if (configSection.hasDocument) {
      // eslint-disable-next-line default-case
      switch (configSection.id) {
        case RECORD_TYPES.VOTER_REGISTRATION: {
          documents[configSection.id] = {
            fileUrl: apiData.file_url,
          }
          break
        }
        case RECORD_TYPES.PLEDGE_CARD: {
          documents[configSection.id] = {
            fileUrl: apiData.pledge_card_url,
          }
          break
        }
        case RECORD_TYPES.PROOF_OF_RESIDENCE: {
          documents[configSection.id] = {
            fileUrl: apiData.proof_of_residence_image_url,
          }
          break
        }
      }
    }
    return documents
  }, {})

const getFormValuesFromRedactedFields = (apiRedactedFields, config) => {
  const redactedFieldConfigs = config.flatMap(section =>
    section.fields.filter(field => field.redacted)
  )

  const clientRedactedFields = {}

  redactedFieldConfigs.forEach(fieldConfig => {
    const redactedAttribute = fieldConfig.attribute
    const baseAttribute = redactedAttribute.replace('has_', '')
    clientRedactedFields[redactedAttribute] =
      apiRedactedFields.includes(baseAttribute)
  })

  return clientRedactedFields
}

const getAttributesFromFieldConfigUnlessRedacted = field => {
  const attributes = getAttributesFromFieldConfig(field)
  if (Array.isArray(attributes) && attributes.includes('redacted_fields')) {
    return []
  }
  return attributes
}

const formatOddFieldsForEdit = (formData, config) => {
  config.forEach(section => {
    section.fields.forEach(field => {
      if (field.component === FIELD_TYPES.DATE) {
        set(
          formData,
          field.attribute,
          formatDateStringAsISOString(get(formData, field.attribute))
        )
      }

      if (field.component === FIELD_TYPES.CHECKBOX_GROUP) {
        const selectedOptions = get(formData, field.attribute)
        if (selectedOptions?.length) {
          set(
            formData,
            field.attribute,
            selectedOptions.map(convertStringToOption)
          )
        }
      }
    })
  })
}

export const buildFormDataFromResponse = (form, config) => {
  const apiFormData = cloneDeep(form)

  const apiToClientAttributeTuples = config
    .flatMap(configSection =>
      configSection.fields.flatMap(getAttributesFromFieldConfigUnlessRedacted)
    )
    .map(attribute => [withPrefixFromApi(attribute), attribute])

  const clientFormData = apiToClientAttributeTuples.reduce(
    (clientFormData, [apiAttribute, clientAttribute]) => {
      set(clientFormData, clientAttribute, get(apiFormData, apiAttribute))
      return clientFormData
    },
    {}
  )

  formatOddFieldsForEdit(clientFormData, config)

  const documents = buildDocumentsFromResponse(apiFormData, config)

  const redactedFields = getFormValuesFromRedactedFields(
    apiFormData.redacted_fields || [],
    config
  )

  return {
    ...clientFormData,
    ...redactedFields,
    documents,
    meta: {},
  }
}
