import { fetchJSON, stringifyParams } from 'utils/req'

export const fetchShift = (shiftId, params) =>
  fetchJSON(
    `/api/v1/shifts/${shiftId}?${stringifyParams(params)}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )

export const fetchShifts = params =>
  fetchJSON(`/api/v1/shifts?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const fetchPackets = params =>
  fetchJSON(`/api/v1/packets?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })

export const postShift = (shiftParams, params) =>
  fetchJSON(
    '/api/v1/shifts',
    'POST',
    { ...params, shift: shiftParams },
    { useJwt: true }
  )

export const putShift = (id, shift) =>
  fetchJSON(
    `/api/v1/shifts/${id}`,
    'PUT',
    { shift },
    {
      useJwt: true,
    }
  )

export const deleteShift = shiftId =>
  fetchJSON(`/api/v1/shifts/${shiftId}`, 'DELETE', null, {
    useJwt: true,
  })

export const fetchScan = (id, fields) => {
  const params = {
    fields: fields || [
      'id',
      'delivery_id',
      'scan_number',
      'county',
      'first_name',
      'last_name',
      { flags: ['id'] },
      { phone_verification_responses: ['notes'] },
      {
        packet: [
          'id',
          'original_filename',
          'file_locator',
          { turf: ['voter_registration_config'] },
        ],
      },
      { turf: ['states', 'voter_registration_config'] },
    ],
  }

  return fetchJSON(
    `/api/v1/forms/${id}?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt: true }
  )
}

export const putScan = (id, attrs, params = {}) =>
  fetchJSON(
    `/api/v1/forms/${id}?${stringifyParams(params)}`,
    'PUT',
    { form: attrs },
    { useJwt: true }
  )

export const putUploadShiftScans = (shiftId, fileData) =>
  fetchJSON(
    `/api/v1/shifts/${shiftId}/upload_scans`,
    'PUT',
    {
      event_args: [fileData],
    },
    { useJwt: true }
  )

export const postDigitalBatch = (shiftId, params) =>
  fetchJSON(
    `/api/v1/canvassing/shifts/${shiftId}/digital_batch`,
    'POST',
    { batch: { ...params, skip_cover_info: true } },
    { useJwt: true }
  )

export const reuploadDigitalBatch = (shiftId, batch) =>
  fetchJSON(
    `/api/v1/canvassing/shifts/${shiftId}/digital_batch`,
    'POST',
    { batch },
    { useJwt: true }
  )

export const postPetitionPacket = packetParams =>
  fetchJSON(
    '/api/v1/petition_packets',
    'POST',
    { petition_packet: packetParams },
    { useJwt: true }
  )

export const assignPetitionPacket = id =>
  fetchJSON(
    `/api/v1/petition_packets/${id}/self_assign`,
    'PATCH',
    {},
    {
      useJwt: true,
    }
  )

export const unassignPetitionPacket = id =>
  fetchJSON(
    `/api/v1/petition_packets/${id}/unassign`,
    'PATCH',
    {},
    {
      useJwt: true,
    }
  )

export const fetchPetitionSignatures = params =>
  fetchJSON(
    `/api/v1/petition_signatures?${stringifyParams(params)}`,
    'GET',
    null,
    { useJwt: true }
  )

export const putPetitionSignature = (id, petition_signature, params) =>
  fetchJSON(
    `/api/v1/petition_signatures/${id}?${stringifyParams(params)}`,
    'PUT',
    { petition_signature },
    {
      useJwt: true,
    }
  )
