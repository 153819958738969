import { useEvent } from 'hooks'
import {
  useState,
  createContext,
  useContext,
  SetStateAction,
  Dispatch,
  ReactNode,
} from 'react'
import { fetchFlag, fetchFlags, updateFlag } from 'requests/flags'
import { getDuplicateFlagFilters } from './utils'

export type CurrentFlag = {
  id: number
  status: 'pending' | 'ready' | 'reviewed' | 'completed'
  action_plan: string
  created_at: string
  viewed_by_users: Array<{
    email: string
  }>
  comments: Array<{
    id: number
    body: string
    created_at: string
    viewed_by_users: Array<{ email: string }>
    author: { id: string; name: string; email: string }
  }>
  packet: {
    id: number
    shift_id: number
    original_filename: string
    scans_count: number
  }
  trigger: {
    id: number
    name: string
    implies_canvasser_issue: boolean
    needs_reupload: boolean
    resource_type: 'canvasser' | 'packet'
  }
  canvasser: { id: number; full_name: string }
  turf: { id: number; name: string }
  triggered_by_forms: Array<{
    id: number
    scan_number: number
    qc_history: Array<{
      created_at: string
    }>
    packet: {
      id: number
      original_filename: string
      scans_count: number
    }
  }>
  shift: {
    status:
      | 'ready_for_scans'
      | 'ready_for_cover_sheet_info'
      | 'ready_for_separation'
      | 'ready_for_qc'
      | 'in_qc'
      | 'ready_for_phone_verification'
      | 'in_phone_verification'
      | 'ready_for_delivery'
      | 'complete'
  }
}

type FetchFlagResponse = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'quality_control/flag': CurrentFlag
}

type FetchFlagsResponse = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'quality_control/flags': Array<CurrentFlag>
}

const CurrentFlagContext = createContext<{
  currentFlag: CurrentFlag | undefined
  setCurrentFlag: Dispatch<SetStateAction<CurrentFlag | undefined>>
  hasDuplicates: boolean
  fetchFlagAction: (id: number, params: any) => Promise<FetchFlagResponse>
  updateFlagAction: (
    id: number,
    data: Partial<CurrentFlag>,
    params: any
  ) => Promise<FetchFlagResponse>
  fetchDuplicateFlags: (isExternalQc: boolean, params: any) => Promise<void>
  fetchAssociatedFlags: (params: any) => Promise<FetchFlagsResponse>
} | null>(null)

export const CurrentFlagProvider = ({
  children,
}: {
  children?: ReactNode | undefined
}) => {
  const [currentFlag, setCurrentFlag] = useState<CurrentFlag | undefined>(
    undefined
  )

  const fetchFlagAction = useEvent(async (id: number, params: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const response = (await fetchFlag(id, params)) as FetchFlagResponse

    const flag = response['quality_control/flag']
    if (flag) {
      setCurrentFlag(flag)
    }

    return response
  })

  const updateFlagAction = useEvent(
    async (id: number, data: Partial<CurrentFlag>, params: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const response = (await updateFlag(id, data, params)) as FetchFlagResponse

      const flag = response['quality_control/flag']
      if (flag) {
        setCurrentFlag(flag)
      }

      return response
    }
  )

  const [hasDuplicates, setHasDuplicates] = useState(false)

  const fetchDuplicateFlags = useEvent(
    async (isExternalQc: boolean, params: any) => {
      if (!currentFlag) throw new Error('No flag currently loaded')
      setHasDuplicates(false)

      const filters = getDuplicateFlagFilters(currentFlag, isExternalQc)
      const request = fetchFlags({
        ...params,
        filters: { rules: filters },
        per: 2,
      }) as Promise<FetchFlagsResponse>

      const response = await request
      if (response['quality_control/flags']?.length) {
        setHasDuplicates(true)
      }
    }
  )

  const fetchAssociatedFlags = useEvent((params: any) => {
    if (!currentFlag) throw new Error('No flag currently loaded')

    return fetchFlags({
      ...params,
      filters: {
        rules: [
          {
            column: 'packet',
            operator: 'is',
            param: currentFlag.packet.id,
          },
          {
            column: 'id',
            operator: 'is',
            param: currentFlag.id,
            invert: true,
          },
          {
            column: 'trigger_implies_canvasser_issue',
            operator: 'is',
            param: true,
            invert: true,
          },
        ],
      },
    }) as Promise<FetchFlagsResponse>
  })

  return (
    <CurrentFlagContext.Provider
      value={{
        currentFlag,
        setCurrentFlag,
        hasDuplicates,
        fetchFlagAction,
        updateFlagAction,
        fetchDuplicateFlags,
        fetchAssociatedFlags,
      }}
    >
      {children}
    </CurrentFlagContext.Provider>
  )
}

export const useCurrentFlagContext = () => {
  const context = useContext(CurrentFlagContext)
  if (context === null) {
    throw new Error(
      'useCurrentFlagContext must be used within a CurrentFlagProvider'
    )
  }
  return context
}
