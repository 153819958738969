import { fetchJSON, stringifyParams } from 'utils/req'
import { packetFields } from 'requests/packets'
import { COMPLETED } from 'constants/qualityControl'
import { isSameDayOrAfter, isSameDayOrBefore } from 'utils/dateTime'

const deliveryParams = {
  fields: [
    'id',
    'turn_in_date',
    'status',
    'form_filter_registration_date_start',
    'form_filter_registration_date_end',
    'form_filter_counties',
    'form_filters_no_county',
    'form_filter_qc_statuses',
    'delivery_method',
    'notes',
    'attached_forms_count',
    'runner_receipt_url',
    { excluded_forms: 'id' },
    { turn_in_location: ['name'] },
    { canvasser: ['full_name', 'vdrs'] },
    { office: ['name', 'qc_config', 'voter_registration_config'] },
  ],
}

export const fetchDelivery = id => {
  const params = stringifyParams(deliveryParams)
  return fetchJSON(`/api/v1/deliveries/${id}?${params}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchDeliveries = incomingParams => {
  const params = stringifyParams({
    ...incomingParams,
    ...deliveryParams,
  })

  return fetchJSON(`/api/v1/deliveries?${params}`, 'GET', null, {
    useJwt: true,
  })
}

export const fetchDeliveryForms = deliveryId => {
  const params = {
    per: Number.MAX_SAFE_INTEGER,
    fields: [
      'id',
      'county',
      'scan_number',
      'created_at',
      'display_name',
      { packet: ['original_filename'] },
      { shift: ['id', 'shift_start'] },
    ],
  }

  return fetchJSON(
    `/api/v1/deliveries/${deliveryId}/registration_forms?${stringifyParams(
      params
    )}`,
    'GET',
    null,
    { useJwt: true }
  )
}

export const fetchPacket = packetId =>
  fetchJSON(
    `/api/v1/packets/${packetId}?${stringifyParams({ fields: packetFields })}`,
    'GET',
    null,
    { useJwt: true }
  )

export const fetchEligiblePackets = deliveryId => {
  const params = stringifyParams({
    fields: [
      'id',
      'original_filename',
      {
        forms: [
          'id',
          { visual_review_responses: ['implies_not_form'] },
          'county',
          'delivery_id',
          'notes',
          'scan_number',
          { phone_verification_responses: ['notes'] },
          { flags: ['status'] },
          { shift: ['id'] },
        ],
      },
      { shift: ['id', 'shift_start', 'shift_end', 'status'] },
      { turf: ['lft', 'rgt'] },
    ],
  })
  return fetchJSON(
    `/api/v1/deliveries/${deliveryId}/packets/eligible?${params}`,
    'GET',
    null,
    {
      useJwt: true,
    }
  )
}

export const updateDeliveryRequest = (deliveryId, attrs) => {
  const params = stringifyParams(deliveryParams)
  return fetchJSON(
    `/api/v1/deliveries/${deliveryId}?${params}`,
    'PUT',
    {
      delivery: attrs,
    },
    { useJwt: true }
  )
}

export const deleteDelivery = deliveryId =>
  fetchJSON(`/api/v1/deliveries/${deliveryId}`, 'DELETE', null, {
    useJwt: true,
  })

export const deliveryEvent = (deliveryId, event, voterRegistrationIds) => {
  const params = stringifyParams(deliveryParams)

  return fetchJSON(
    `/api/v1/deliveries/${deliveryId}/${event}?${params}`,
    'PUT',
    {
      event,
      voter_registration_ids: voterRegistrationIds,
    },
    { useJwt: true }
  )
}

export const fetchAvailableCountiesRequest = () => {
  const params = { fields: ['county', { shift: ['status'] }] }
  return fetchJSON(`/api/v1/forms?${stringifyParams(params)}`, 'GET', null, {
    useJwt: true,
  })
}

export const getSelectedCounties = (filteredPackets, selectedFormIds) => [
  ...new Set(
    filteredPackets
      .flatMap(packet => packet.forms)
      .filter(form => selectedFormIds.includes(form.id))
      .map(form => form.county)
  ),
]

export const getFilterQcStatuses = (currentDelivery, pendingChanges = {}) => {
  const deliveryRequiresQc = currentDelivery
    ? currentDelivery.office.qc_config.required_for_delivery
    : true

  if (deliveryRequiresQc) return [COMPLETED]

  return (
    pendingChanges.form_filter_qc_statuses ??
    currentDelivery.form_filter_qc_statuses
  )
}

const packetWithShiftInFilterRange =
  (delivery, pendingChanges) =>
  ({ shift }) => {
    const startDate =
      pendingChanges.form_filter_registration_date_start ??
      delivery.form_filter_registration_date_start

    const endDate =
      pendingChanges.form_filter_registration_date_end ??
      delivery.form_filter_registration_date_end

    return (
      isSameDayOrAfter(startDate, shift.shift_start) &&
      isSameDayOrBefore(endDate, shift.shift_end)
    )
  }

const packetWithEligibleShiftStatus =
  (delivery, pendingChanges) =>
  ({ shift }) => {
    const qcStatuses = getFilterQcStatuses(delivery, pendingChanges)

    return qcStatuses.includes(shift.status)
  }

const scanWithMatchingCounty = (delivery, pendingChanges) => scan => {
  if (!scan.county) {
    const noCounty =
      pendingChanges.form_filters_no_county ?? delivery.form_filters_no_county

    return noCounty
  }

  const counties =
    pendingChanges.form_filter_counties ?? delivery.form_filter_counties

  return counties.includes(scan.county)
}

export const getEligibleFormIds = ({
  delivery,
  pendingUpdates,
  packets,
  searchTerm,
  options = {
    county: true,
  },
}) => {
  if (!delivery || !packets) return []

  const pendingChanges = pendingUpdates.reduce(
    (combinedChanges, currentChange) => ({
      ...combinedChanges,
      ...currentChange.change,
    }),
    {}
  )

  const packetsMatchingSearch = searchTerm
    ? packets.filter(p =>
        p.scan_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : packets

  const eligiblePackets = packetsMatchingSearch
    .filter(packetWithShiftInFilterRange(delivery, pendingChanges))
    .filter(packetWithEligibleShiftStatus(delivery, pendingChanges))

  let eligibleScans = eligiblePackets
    .flatMap(({ forms }) => forms)
    .filter(form => !form.delivery_id)
    .filter(
      form =>
        !form.visual_review_responses.find(
          response => response.implies_not_form === true
        )
    )

  if (options.county) {
    eligibleScans = eligibleScans.filter(
      scanWithMatchingCounty(delivery, pendingChanges)
    )
  }

  return eligibleScans.map(({ id }) => id)
}

export const buildCountyOptions = ({ eligibleFormIds, packets }) => {
  const counts = {}

  packets.forEach(packet => {
    packet.forms
      .filter(form => form.county !== null)
      .forEach(form => {
        if (eligibleFormIds.indexOf(form.id) === -1) {
          counts[form.county] ??= 0
        } else if (counts[form.county]) {
          counts[form.county] += 1
        } else {
          counts[form.county] = 1
        }
      })
  })

  return Object.entries(counts).map(([county, count]) => ({
    id: county,
    name: `${county} (${count})`,
  }))
}
