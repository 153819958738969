import {
  SectionLabel,
  List,
  ListItem,
  ContentBlock,
  Font,
  ButtonBlock,
  Button,
} from '@politechdev/blocks-design-system'
import { CanvasserContext } from 'canvassers/CanvasserContext/CanvasserContext'
import moment from 'moment-timezone'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FlagCommentsModal from 'qualityControlReports/modals/FlagCommentsModal/FlagCommentsModal'
import { Paginator } from 'components/index'
import { useCurrent } from 'contexts/index'

const CanvasserIssueFlags = () => {
  const { t } = useTranslation()
  const {
    currentUser: { time_zone: userTimezone },
  } = useCurrent()

  const [currentPage, setCurrentPage] = useState(0)
  const { currentCanvasser } = useContext(CanvasserContext)

  const canvasserIssueFlags = currentCanvasser.flags
    .filter(flag => flag.trigger.implies_canvasser_issue === true)
    .map(flag => ({
      id: flag.id,
      flagName: flag.trigger.name,
      packetName: flag.packet?.original_filename,
      lastCommentTime:
        flag.comments.length > 0
          ? flag.comments[flag.comments.length - 1].created_at
          : null,
      flaggedTime: flag.created_at,
      flaggedScans: flag.triggered_by_forms.map(form => form.scan_number),
    }))
    .sort((a, b) => Date.parse(b.flaggedTime) - Date.parse(a.flaggedTime))

  const paginatedFlags = canvasserIssueFlags.slice(
    currentPage * 5,
    Math.min(currentPage * 5 + 5, canvasserIssueFlags.length)
  )

  const [commentModalId, setCommentModalId] = useState()
  const [commentModalOpen, setCommentModalOpen] = useState(false)

  return (
    <section>
      <SectionLabel>{t('Flags')}</SectionLabel>
      <List
        itemData={paginatedFlags}
        render={item => (
          <ListItem>
            <ContentBlock>
              <Font.Copy>{item.flagName}</Font.Copy>
              <Font.Copy variant="hint">
                {item.packetName}
                {item.lastCommentTime && (
                  <>
                    {' - '}
                    {t('New comment')}{' '}
                    {moment
                      .utc(item.lastCommentTime)
                      .tz(userTimezone)
                      .fromNow()}
                  </>
                )}
              </Font.Copy>
            </ContentBlock>
            <ContentBlock>
              <Font.Copy>
                {t('Flagged')}{' '}
                {moment.utc(item.flaggedTime).tz(userTimezone).fromNow()}
              </Font.Copy>
              <Font.Copy variant="hint">
                {item.flaggedScans.map(num => `${t('Scan')} ${num}`).join(', ')}
              </Font.Copy>
            </ContentBlock>
            <ButtonBlock justify="right">
              <Button.Secondary
                onClick={() => {
                  setCommentModalId(item.id)
                  setCommentModalOpen(true)
                }}
                disabled={!item.lastCommentTime}
              >
                {t('View comments')}
              </Button.Secondary>
            </ButtonBlock>
          </ListItem>
        )}
      />
      <FlagCommentsModal
        isOpen={commentModalOpen}
        setIsOpen={setCommentModalOpen}
        flagId={commentModalId}
      />
      <Paginator
        currentPage={currentPage + 1}
        totalPages={Math.ceil(canvasserIssueFlags.length / 5) || 1}
        onNext={() => setCurrentPage(old => old + 1)}
        onPrevious={() => setCurrentPage(old => old - 1)}
        justify="left"
      />
    </section>
  )
}

export default CanvasserIssueFlags
