import React from 'react'
import { StaticListItem } from 'components'
import {
  Button,
  ButtonBlock,
  Icon,
  Font,
} from '@politechdev/blocks-design-system'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useFormListItem from './hooks/useFormListItem'

const FormListItem = ({
  isExcluded,
  deliveryId,
  form,
  isIneligible,
  handleExclude,
  handleInclude,
}) => {
  const { t } = useTranslation()

  const {
    county,
    scan_number,
    shift,
    id,
    personName,
    notesTabIsOpen,
    setNotesTabIsOpen,
    allNotes,
    notesButtonLabel,
    ineligibleLabel,
    hasIssues,
    exclude,
    include,
  } = useFormListItem(form, handleExclude, handleInclude)

  return (
    <StaticListItem
      render={() => (
        <div className="form-list-item">
          <div className="form-list-item__main">
            <div>
              <p className="scroll-list__item--primary">{`Scan #${scan_number} - ${
                personName || 'Data pending'
              }`}</p>
              <p className="scroll-list__item--secondary">
                {`${county} County` || t('No county')}
              </p>
            </div>
            {isExcluded || isIneligible ? (
              <Font.Copy>
                {isExcluded ? t('Excluded') : ineligibleLabel}
                <Icon.Ban aria-label={t('Ineligible')} />
              </Font.Copy>
            ) : (
              <Font.Copy>
                {t('Included')}
                <Icon.Check aria-label={t('Included')} />
              </Font.Copy>
            )}
          </div>
          <div className="form-list-item__actions">
            <div className="form-list-item__indicators">
              <ButtonBlock>
                <Button
                  onClick={() => setNotesTabIsOpen(prevState => !prevState)}
                  disabled={allNotes.length === 0}
                >
                  <Font.Copy>
                    {notesTabIsOpen ? (
                      <Icon.AngleUp alt="" />
                    ) : (
                      <Icon.CommentAltLines aria-label={t('Notes')} />
                    )}{' '}
                    {notesButtonLabel}
                  </Font.Copy>
                </Button>
                {hasIssues && (
                  <Font.Copy>
                    <Icon.Flag alt="" />
                    {t('Flag')}
                  </Font.Copy>
                )}
              </ButtonBlock>
            </div>
            <ButtonBlock justify="right">
              {!isIneligible &&
                (isExcluded ? (
                  <Button onClick={include}>{t('Include')}</Button>
                ) : (
                  <Button.Danger onClick={exclude}>
                    {t('Exclude')}
                  </Button.Danger>
                ))}
              <Link
                to={`/collect/voter_registration/shifts/${shift.id}/forms/${id}?source=deliveries&sourceId=${deliveryId}`}
              >
                <Button>{t('Inspect')}</Button>
              </Link>
            </ButtonBlock>
          </div>
          <div
            className={`form-list-item__notes ${
              notesTabIsOpen ? 'form-list-item__notes--open' : ''
            }`}
          >
            <div className="qc-notes__container">
              {allNotes.map(n => (
                <div className="qc-notes__item">
                  <p className="qc-notes__item__content">{n.note}</p>
                  <span className="qc-notes__item__source">{`From ${n.source}`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default FormListItem
