import React, { useEffect } from 'react'
import { StaticList } from 'components'
import { useTranslation } from 'react-i18next'
import { orderBy } from 'lodash'
import PacketListItem from './PacketListItem'
import PacketListHeader from './PacketListHeader'
import { useDeliveryState, useDeliveryActions } from '../DeliveryContext'

const PacketList = () => {
  const scrollContainer = React.createRef()

  const {
    filteredPackets,
    packets,
    packetsLoading,
    packetSearchTerm,
    currentPacket,
    packetSortType: sortType,
    packetSortOrder: sortOrder,
  } = useDeliveryState()

  const { makePacketRequest, setCurrentPacket } = useDeliveryActions()
  const { t } = useTranslation()

  useEffect(() => {
    scrollContainer && scrollContainer.current.scrollTo(0, 0)
  }, [packets, packetSearchTerm])

  const filteredPacket = filteredPackets[0]
  useEffect(() => {
    if (filteredPacket) {
      makePacketRequest(filteredPacket.id)
    } else {
      setCurrentPacket({
        scan_name: '',
        shift: {},
        forms: [],
      })
    }
  }, [filteredPacket?.id])

  const sortedPackets = orderBy(
    filteredPackets,
    packet =>
      sortType === 'alpha' ? packet.scan_name : packet.shift.shift_start,
    [sortOrder === 'ASC' ? 'asc' : 'desc']
  )

  return (
    <div className="form-viewer__sidebar">
      <PacketListHeader />
      <StaticList
        ref={scrollContainer}
        loading={packetsLoading}
        itemData={sortedPackets}
        emptyMessage={t('No packets found')}
        render={packet => (
          <PacketListItem
            key={packet.id}
            packet={packet}
            active={packet.id === currentPacket.id}
          />
        )}
      />
    </div>
  )
}

export default PacketList
